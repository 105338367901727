import React from 'react';
import Layout from '../components/layout';
import Seo from "../components/seo";
import HeaderPage from '../utils/HeaderPage';
import SideBar from '../components/SideBar/SideBar';
import Parser  from 'react-html-parser'
import { useStaticQuery, graphql } from "gatsby";
import { isSSR, updatePageContent } from '../utils/utils';
const getdata = graphql`
{
  wpgraphql {
    posts(where: {categoryName: "Founders And Entrepreneurs"}, first: 100) {
      nodes {
        slug
        title
        id
        content
        date
        categories {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            mediaItemUrl
          }
        }
      }
    }
    page(id: "cG9zdDoyNTYzNQ==") {
      id
      title
      content
      uri
      seo {
        canonical
        metaDesc
        metaKeywords
        focuskw
        title
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphTitle
        opengraphType
        opengraphImage {
          sourceUrl
        }
      }    
    }
  }
}
  `
const ForFoundersEntrepreneurs = () => {
  const data = useStaticQuery(getdata);
  const common = data.wpgraphql.page;
  const patents = data.wpgraphql.posts.nodes;
  return (
    <Layout>
      <Seo title={common.title} description={common.seo.metaDesc} canonical={ `${common.uri}`} seo={common.seo}  />
      <HeaderPage headerTxt={common.title}></HeaderPage>
      <section className='mainSpacing2'>
        <div className="container industries-outr ps-0">
          <div className='row'>
            <div className='col-md-9'>
                <div className='mb-3'>
                  <article>
                    <>
                      <div className="accordion-content">
                      {Parser(isSSR() ? common.content : updatePageContent(common.content, patents))}
                      </div>
                    </>
                  </article>
                </div>
            </div>
            <div className='col-md-3'>
              <SideBar></SideBar>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ForFoundersEntrepreneurs;
